//@import "../../_var.scss";
@import "../../css/themeify";
.tips {
  // opacity: .8;
  position: absolute;
  //background: $tips-bg;
  //box-shadow: 0 4px 8px 0;
  padding: .1rem .2rem;
  font-size: .14rem;
  border-radius: 10px;
  //color: $tips-font;
  z-index: 99999;
  @include themeify {
    background: themed('minor-font-color');
    color: themed('normal-font-color');
  }
}

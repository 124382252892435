@import "../../css/themeify.scss";

.container {
  position: fixed;
  left: 0;
  top: 0;
  //transform: translateX(-50%);
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .12);
}

.loadingCyl {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  border: 2px solid;
  border-radius: 50%;
  @include themeify {
    border-color: themed('err-color') transparent transparent;
  }
  //border-color: $high-lighting-font transparent transparent;
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

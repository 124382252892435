@import "./themeify";
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 625%; //10 ÷ 16 × 100% = 62.5%
}

body {
  @include themeify {
    background: themed("page-bg-color");
  }
  font-size: 12px;
  font-family: "STHeiti", "Microsoft JhengHei", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  -webkit-user-select: auto; /* webkit浏览器 */
}

ul,
li,
ol,
h3,
table,
td,
th,
tr {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

input,
button,
textarea {
  border: none;
  background: transparent;
  outline: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-x {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  @include themeify {
    background: themed("page-bg-color");
  }
}

#root > div {
  width: 100%;
  margin: 0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input {
  outline: none !important;
  -webkit-appearance: none !important; /*去除系统默认的样式*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; /* 点击高亮的颜色*/
}
input::-webkit-input-placeholder {
  /* placeholder颜色  */
  @include themeify {
    color: themed("minor-font-color");
  }
}
input {
  @include themeify {
    color: themed("main-font-color");
  }
  &.err {
    @include themeify {
      border: 1px solid themed("err-color");
    }
  }
}
